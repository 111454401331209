@tailwind base;
@tailwind components;
@tailwind utilities;

head,
body,
#app {
  margin: 0;
  padding: 0;
}
